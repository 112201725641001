// extracted by mini-css-extract-plugin
export var card_wrapper = "card-module--card_wrapper--uDiJt";
export var card_header = "card-module--card_header--3ma1f";
export var card_icon = "card-module--card_icon--1xaN-";
export var card_image = "card-module--card_image--2VbEP";
export var card_subtitle = "card-module--card_subtitle--2gteH";
export var tag_cloud = "card-module--tag_cloud--3tIWL";
export var card_links = "card-module--card_links--Ee64O";
export var link_github = "card-module--link_github--2c4Jh";
export var link_website = "card-module--link_website--1V-tE";
export var link_package = "card-module--link_package--1V-7f";
export var link_docs = "card-module--link_docs--3CWY1";
export var one_tag_container = "card-module--one_tag_container--3uneY";
export var two_tag_container = "card-module--two_tag_container--lSYWe";
export var three_tag_container = "card-module--three_tag_container--39dCI";